import React, { useState } from "react"
import GitexPopupModal from "../../core/GitexPopupModal";
import H2 from "../../core/H2";

const PopupCalandly = () => {
    // eslint-disable-next-line no-unused-vars
    const [openGitex, setOpenGitex] = useState(false)

    const openGitexModal = () => {
        setOpenGitex(true)
    }
    const closeGitexModal = () => {
        setOpenGitex(false)
    } 
    return (
        <div className="bg-transperent relative overflow-hidden flex justify-center align-middle" >
            <div className="relative py-8 md:py-16 lg:py-0 xl:py-0 flex justify-center align-middle lg:my-auto">
                <div className="w-screen px-4 sm:px-6 flex flex-wrap content-center justify-center item-center ">
                    <div className=" grid grid-cols-12 lg:gap-8">
                        <div className="mt-12 md:mt-24 lg:mt-0 relative lg:max-w-2xl sm:mx-auto lg:mx-0 lg:col-span-6 col-span-12 lg:flex lg:items-center aos-init aos-animate" data-aos="fade-right">
                            <div className="relative w-full  rounded-lg  block">
                                <div className="overflow-hidden flex justify-center">
                                    <div className="h-auto w-full">
                                        <img
                                            src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Section-2-london-tech-week.webp"
                                            alt="Tech Events 2023"
                                            width="250px"
                                            height="250px"
                                            // title="Tech Events"
                                            className="w-full h-full object-cover cursor-pointer"
                                            loading="lazy"
                                            onClick={openGitexModal}
                                            aria-hidden="true"
                                        />
                                    </div>

                                </div>
                            </div>
                            <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} />
                        </div>
                        <div className="flex items-center h-full order-0 mt-10 lg:mt-0 text-center lg:max-w-2xl mx-auto lg:col-span-6 col-span-12 flex-col justify-center lg:text-left md:text-left aos-init aos-animate" data-aos="fade-left">
                            <div
                                className={`w-full bg-cover bg-center overflow-hidden`}
                            >
                                <div
                                    className={`flex items-center h-full  w-full `}
                                >
                                    <div
                                        className="flex flex-col lg:items-start items-center lg:text-left text-center lg:py-16 m-4"
                                    >
                                        <H2
                                            title="London Tech Week 2023"
                                            className='max-w-5xl text-start'
                                        />
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: `
                                                The digital landscape has transformed over the past decade, and we, an experienced software development company, have witnessed the change. WebMob Technologies is renowned for its agile development methodology, tech advancement integrations, feature-rich and highly interactive applications.  </br></br>
                                                The tech spaceship is guarded by 120+ tech brains providing a wide range of custom app development services using different languages and frameworks like Python, Django, VueJS, Flutter, React Native, ReactJS, Blockchain, etc. We have developed over 500+ web and mobile app projects in 20+ industries served across 25+ countries. </br></br>
                                                Get a free consultation and a quote from our team.  `,
                                            }}
                                            className={`pt-6 mx-auto text-lg font-sans max-w-4xl`} />
                                        <div className="flex md:flex-row flex-col md:gap-9 gap-1">
                                            <button
                                                className="mt-5 md:mt-8 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                                                onClick={openGitexModal}
                                            // className="mt-5 py-3 hover:bg-blue-500 focus:bg-blue-600 px-8 bg-transparent border-white hover:border-transparent focus:border-transparent border rounded text-white"
                                            >
                                               Book Your Meeting
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PopupCalandly
